import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const constantRoutes = [
    
    {
        path: '/',
        name: '主页面',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'index',
        component: resolve => require(['@/views/index/index.vue'], resolve),
        meta: {
            keepAlive: false
        }
    },
    
    {
        path: '/login',
        name: 'login',
        component: resolve => require(['@/views/login.vue'], resolve),
        meta: {
            keepAlive: false
        }
    }
];

export default new VueRouter({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})
