import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import router from './router/index'
import './assets/css/style.less';
import api from './assets/request/api' // 导入api接口
import { parseTime} from "./utils/haotai";
Vue.prototype.parseTime = parseTime
Vue.prototype.$api = api;
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
